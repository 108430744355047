import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import Video from "../../components/video"

import { StaticImage } from "gatsby-plugin-image"

const Page = () => {
  const metadata = {
    title: "Ron Houde",
    description:
      "Ron Houde is a trial and appellate lawyer practicing insurance defense, personal injury, and general litigation. He practices throughout Connecticut in state, federal, and tribal courts.",
    heroH1: "Ron Houde",
    heroImage: "photo-bg-staff.jpg",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Ron Houde</h2>
            <div className="w-full md:w-1/2 mb-8 float-right">
              <Video videoSrcURL="https://www.youtube.com/embed/B47UBaGy_40?rel=0" />
            </div>
            <div>
              <p className="mb-8">
                Ron Houde is an associate at Bartlett & Grippe LLC. He is a trial
                and appellate lawyer practicing in the areas of insurance defense,
                personal injury, and general litigation. He practices throughout
                Connecticut in state, federal, and tribal courts. Ron seeks
                efficient and just resolution for all his clients whether through
                trial, dispositive motions, or alternative dispute resolution.
              </p>
              <p className="mb-8">
                In 2018 and 2019, Connecticut Super Lawyers selected Ron as a
                Rising Star in Civil Defense Litigation, an honor given to less
                than 2.5% of practicing attorneys under the age of 40 or in
                practice for 10 years or less. Additional details on the selection
                process can be found here. In 2018, the Connecticut Law Tribune
                honored him as a New Leader in the Law.
              </p>
              <p className="mb-8">
                Prior to joining the firm, Ron worked at two insurance defense
                firms in Hartford. He also served as a clerk to the Honorable
                Judges of the Hartford Superior Court. He received his J.D. from
                Western New England University School of Law in 2015 and his B.A.
                in Economics from the University of Connecticut in 2010. He is
                admitted to practice in the State and Federal Courts of
                Connecticut, the Mashantucket Pequot Tribal Court, the Mohegan
                Gaming Disputes Court, and the U.S Court of Appeals for the Second
                Circuit.
              </p>
              <p className="mb-8">
                Ron is an active member of the Connecticut Bar Association, the
                George W. Crawford Black Bar Association, and a past member of the
                Oliver Ellsworth American Inn of Court. He is engaged in his
                community and serves on the Board of Directors for Kinsella Arts
                Incorporated and the Wallingford Dog Lovers Association. He is a
                2019 graduate of Leadership Greater Hartford’s Quest Program.
              </p>
              <p className="mb-8">
                Ron grew up in Branford, Connecticut and now resides in
                Wallingford with his fiancée and rescue dog.
              </p>
            </div>
            <div class="rounded-2xl bg-base-300 shadow-lg flex mb-16 w-full sm:max-w-max mr-0 sm:mr-4">
              <figure style={{ height: "150px", width: "150px" }}>
                <StaticImage
                  className="rounded-l-2xl"
                  height={"150"}
                  quality="100"
                  src="../../images/atty_houde.jpg"
                  formats={["auto", "webp", "avif"]}
                  alt="Photo of Ron Houde"
                  imgStyle={{
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                  }}
                />
              </figure>
              <div class="px-4 pb-2 text-gray-900">
                <p className="text-xl font-bold">Ron Houde</p>
                <p className="">Associate</p>
                <p className="">
                  <a href="tel:+1-203-272-1157">203-272-1157</a> <strong>phone</strong>
                </p>
                <p className="">
                  203-250-1835 <strong>fax</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
